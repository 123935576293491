import React from "react";
import ReactMarkdown from "react-markdown";
import { graphql, StaticQuery } from "gatsby";
import "./partners.scss";
import Button from "@components/button";
import Link from "gatsby-link";

const Partners = ({ data }) => {
	if (!data) return null;
	const { partners: { edges = {} } = {} } = data;

	const collection = edges
		.map(item => item.node.childMarkdownRemark.frontmatter)
		.sort((a, b) => a.order - b.order);

	return (
		<section className="full-width partners">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
						<h2>Партнеры</h2>
						<h4>
							АО «Транспортная карта» открыта к предложениям о сотрудничестве
						</h4>
					</div>

					{collection &&
						collection.map(({ title, content, image, order }) => (
							<div
								key={order}
								className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10"
							>
								<div className="partners__partner">
									{image ? (
										<img
											alt={title}
											className="partners__partner__image"
											src={image}
										/>
									) : (
										<div className="partners__partner__default-image" />
									)}
									<div className="partners__partner__content">
										<h4>{title}</h4>
										<ReactMarkdown source={content} />
									</div>
								</div>
							</div>
						))}

					<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
						<h4>
							Свяжитесь с нами, если вы заинтересованы в партнерских отношениях
						</h4>
						<Link to="/became-partner">
							<Button title="Стать партнером" />
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};

export default props => (
	<StaticQuery
		query={graphql`
			query {
				partners: allFile(filter: { relativeDirectory: { eq: "partners" } }) {
					edges {
						node {
							childMarkdownRemark {
								frontmatter {
									title
									content
									image
									order
								}
							}
						}
					}
				}
			}
		`}
		render={data => <Partners data={data} {...props} />}
	/>
);
